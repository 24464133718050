<template>
  <van-cell border :title="'缴费渠道：'+sourceData.jfqd" class="jfjl-item">
    <template #label>
      <p>缴费时间： {{sourceData.ywsj}}</p>
      <p>缴费结果：<span :class="{'color-danger': sourceData.jfzt == '作废'}">{{sourceData.jfzt}}</span></p>
    </template>
    <p class="amount">{{sourceData.jfje | currency}}</p>
  </van-cell>
</template>

<script>
  export default {
    name: 'JfjlItem',
    props: {
      sourceData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {

      }
    },
    methods: {

    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/styles/variable.scss';

  .jfjl-item /deep/ {
    // padding: 16px 0;
    .van-cell {
      padding: 5px 16px;
    }

    .van-cell__title {
      flex: 7;
    }

    .van-cell__value {
      flex: 5;
      .amount {
        font-size: 18px;
      }
    }

    .color-danger {
      color: #ff4a4a;
    }
  }
</style>